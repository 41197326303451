<template>
    <div class="store-wrapper">
        <el-scrollbar class="store-container" :native="false">
            <div class="web-top-wrapper">
                <div class="web-top">
                    <div class="top-left">
                        <!--                        <div class="web-logo">-->
                        <!--                            <img :src="require('../../../assets/images/jd-logo.png')" alt="">-->
                        <!--                        </div>-->
                        <el-select v-model="classValue" placeholder="全部分类" style="width: 110px">
                            <el-option v-for="item in classList" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="top-search">
                        <el-input v-model="search" placeholder="请输入搜索内容" style="width: 480px;"></el-input>
                        <div class="btn search-all">搜全站</div>
                        <div class="btn search-current">搜本站</div>
                    </div>
                </div>
            </div>
            <div class="web-tab-wrapper">
                <div class="web-tab">{{goodInfo.goods_type}}</div>
            </div>
            <div class="good-info-wrapper">
                <div class="good-info">
                    <div class="good-cover">
                        <swiper ref="mySwiper" :options="swiperOptions" class="store-banner">
                            <swiper-slide class="item" v-for="(imgItem, imgIndex) in goodInfo.goods_class_img"
                                          :key="`goods_image_${imgIndex}`">
                                <img :src="imgItem">
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </div>
                    <div class="right-content">
                        <p class="good-title">{{goodInfo.goods_name}}</p>
                        <div class="good-price">
                            <div class="price">
                                <span class="text">价格</span>
                                <span class="num">&yen;{{goodInfo.goods_market_price}}</span>
                            </div>
                            <div class="sales-price">
                                <span class="text">折扣价</span>
                                <span class="num">&yen;{{goodInfo.goods_discount_price}}</span>
                            </div>
                        </div>
                        <div class="good-address">
                            <span class="text">配送   至</span>
                            <el-select v-model="addressList" placeholder="请选择地区" style="width: 110px" size="mini">
                                <el-option label="全部地区" value="1"></el-option>
                            </el-select>
                            <span class="text">有货</span>
                            <span class="text">免运费</span>
                        </div>
                        <div class="good-color">
                            <span class="text">颜色</span>
                            <div class="color-content">
                                <div class="color-img">
                                    <img :src="goodInfo.goods_master_img ? goodInfo.goods_master_img : require('../../assets/image/noimg.png')"
                                         alt="">
                                </div>
                                <div class="color-text">颜色</div>
                            </div>
                        </div>
                        <div class="good-num">
                            <el-input-number v-model="goodInfo.goods_stock" size="small" :min="1"
                                             label="描述文字"></el-input-number>
                        </div>
                        <div class="good-btn">
                            <el-button class="btn-red-dark">加入购物车</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrapper" style="margin-top: 30px">
                <div class="web-tab-wrapper">
                    <div class="web-tab">
                        <div class="tab-item">商品详情</div>
                    </div>
                </div>
                <div class="detail-content" v-html="goodInfo.goods_body"></div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {live_script_goods_info} from '@/utils/apis.js'
    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        data() {
            return {
                goodsId: Number(this.$route.query.id) || null,
                search: '',
                classList: [
                    {
                        value: '1',
                        label: '全部分类'
                    },
                    // {
                    //     value: '2',
                    //     label: '卷发棒'
                    // }, {
                    //     value: '3',
                    //     label: '直发棒'
                    // }, {
                    //     value: '4',
                    //     label: '蛋卷棒'
                    // }
                ],
                classValue: '全部分类',
                goodInfo: {
                    id: null,
                    goods_type: '',
                    goods_stock: null,
                    goods_status: 0,
                    goods_sku: '',
                    goods_name: '',
                    goods_master_img: '',
                    goods_market_price: '',
                    goods_discount_price: '',
                    goods_desc: '',
                    goods_commission_rate: '',
                    goods_class_img: [],
                    goods_body: '',
                },
                addressList: '请选择地区',
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        renderBullet: (index, className) => {
                            if (this.goodInfo.goods_class_img.length === 0) {
                                return ''
                            }
                            return `<span class="${className}"><img src="${this.goodInfo.goods_class_img[index]}"></span>`
                        },
                    },
                },
            }
        },
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        mounted() {
            this.getGoodsInfo();
        },
        methods: {
            //请求商品详情
            getGoodsInfo() {
                live_script_goods_info(this.goodsId).then(res => {
                    if (res.code === 200) {
                        this.goodInfo = res.data
                        if (this.goodInfo.goods_class_img.length > 0) {
                            this.goodInfo.goods_class_img = this.goodInfo.goods_class_img.filter(item => item !== '')
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .store-wrapper {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: #fff;
    }

    .store-container {
        /*min-width: 1200px;*/
        height: 100vh;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                min-width: 1200px;
            }
        }

        .web-top-wrapper {
            .web-top {
                width: 1200px;
                height: 106px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .top-left {
                    display: flex;

                    .web-logo {
                        width: 134px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 60px;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    ::v-deep .el-select {
                        /*margin-left: 60px;*/
                        .el-input__inner {
                            border-radius: 0;
                        }
                    }
                }

                ::v-deep .top-search {
                    display: flex;
                    align-items: center;

                    .el-input {
                        .el-input__inner {
                            border-radius: 0;
                            border-width: 2px 0 2px 2px;
                            border-color: #DF0200;
                            border-style: solid;
                        }
                    }

                    .btn {
                        width: 60px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                        cursor: pointer;

                        &.search-all {
                            background: #B61D1D;
                        }

                        &.search-current {
                            background: #474E5C;
                        }
                    }
                }
            }
        }

        .web-tab-wrapper {
            background: #F2F2F2;

            .web-tab {
                width: 1200px;
                margin: 0 auto;
                height: 40px;
                line-height: 40px;
                color: #666;

                .tab-item {
                    color: #fff;
                    background: #B61D1D;
                    width: 108px;
                    text-align: center;
                }
            }
        }

        .good-info-wrapper {
            margin-top: 20px;

            .good-info {
                width: 1200px;
                margin: 0 auto;
                display: flex;

                .good-cover {
                    position: relative;

                    .swiper-container {
                        width: 350px;
                        height: 450px;

                        .swiper-slide {
                            width: 350px !important;
                            height: 350px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #fafafa;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }

                    ::v-deep .swiper-pagination {
                        width: 350px;
                        display: flex;
                        margin: 20px -5px;
                        bottom: 0;
                        /*flex-wrap: wrap;*/
                        .swiper-pagination-bullet {
                            width: 60px;
                            height: 60px;
                            background: #fafafa;
                            border: 2px solid transparent;
                            box-sizing: border-box;
                            margin: 0 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 1;
                            border-radius: 0;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }

                            &.swiper-pagination-bullet-active {
                                border-color: #B61D1D;
                            }
                        }
                    }
                }

                .right-content {
                    margin-left: 40px;
                    width: 1%;
                    flex: 1;

                    .good-title {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .good-price {
                        margin-top: 20px;
                        background: #F7F7F7;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        padding-left: 16px;

                        .price {
                            color: #999;

                            .text {

                            }

                            .num {
                                margin-left: 10px;
                                font-size: 16px;
                                text-decoration: line-through;
                            }
                        }

                        .sales-price {
                            margin-left: 30px;

                            .text {
                                color: #999;
                            }

                            .num {
                                margin-left: 10px;
                                color: #FF0000;
                                font-size: 16px;
                            }
                        }
                    }

                    .good-address {
                        margin-top: 20px;
                        color: #999;

                        .text {
                            margin-right: 20px;
                        }

                        .el-select {
                            margin-right: 20px;
                        }
                    }

                    .good-color {
                        margin-top: 20px;
                        display: flex;

                        .text {
                            color: #999;
                        }

                        .color-content {
                            flex: 1;
                            width: 1%;
                            margin-left: 20px;
                            display: flex;

                            .color-img {
                                width: 60px;
                                height: 60px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #B61D1D;
                                box-sizing: border-box;

                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }

                            .color-text {
                                margin-left: -1px;
                                border: 1px solid #B61D1D;
                                box-sizing: border-box;
                                background: #F7F7F7;
                                width: 60px;
                                height: 60px;
                                text-align: center;
                                line-height: 60px;
                            }
                        }
                    }

                    .good-num {
                        margin-top: 20px;
                    }

                    .good-btn {
                        margin-top: 20px;
                    }
                }
            }
        }

        .content-wrapper {
            .web-tab-wrapper {
                background: #F2F2F2;

                .web-tab {
                    width: 1200px;
                    margin: 0 auto;

                    .tab-item {
                        width: 120px;
                        line-height: 40px;
                        background: #B61D1D;
                        text-align: center;
                        color: #fff;
                    }
                }
            }

            ::v-deep .detail-content {
                text-align: center;
                width: 1200px;
                margin: 20px auto;

                img {
                    max-width: 100%;
                    display: inline-block;
                    margin-top: 10px;
                }

                ul {

                }

                li {
                    list-style-type: none;
                }
            }
        }
    }

    ::v-deep p {
        margin: 0;
    }

    .btn-red-dark {
        width: 140px;
        height: 46px;
        border-radius: 0;
        background: #B61D1D;
        border-color: #B61D1D;
        color: #fff;
    }
</style>